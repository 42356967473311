export type Address = {
  name?: string;
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  state: string;
  zipCode: string;
  zipCodeSuffix?: string;
  email: string;
  country: {
    code: string;
    name: string;
  };
  mobileNumber?: {
    countryCode: string;
    number: string;
  };
};

export interface Organization {
  name: string;
  email: string;
  organizationId?: string;
  mobileNumber: {
    countryCode: string;
    number: string;
  };
  status?: string;
  billingAddress: Address;
  isDeleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
  _id?: string;
  subscription?: Subscription;
  timezone: string;
}
type Subscription = {
  subscriptionPlanId: string;
  subscriptionId: string;
  status: string;
  startDate: string;
  endDate: string;
  stripeData: {
    subscriptionId: string;
    customerId: string;
  };
  createdAt: string;
  updatedAt: string;
  _id: string;
};
export interface Warehouse {
  name: string;
  email: string;
  organizationId?: string;
  warehouseId?: string;
  mobileNumber: {
    countryCode: string;
    number: string;
  };
  status?: string;
  shippingAddress: Address;
  isDeleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
  _id?: string;
}

export interface Client {
  name: string;
  email: string;
  status: string;
  organizationId: string;
  merchantId?: string;
  mobileNumber: {
    countryCode: string;
    number: string;
  };
  billingAddress: Address;
  settings?: {
    fbaWorkflowVersion?: string;
  };
}

export interface Integration {
  integrationName: string;
  connections: Connection[];
  integrationId: string;
  integrationTags: string[];
  status: string;
  updatedAt: string;
  merchantId: string;
  credentials: {
    region: string;
    refreshToken: string;
    sellerId: string;
  };
}

export interface Connection {
  connectionId: string;
  connectionName: string;
  details: {
    marketplaceId: string;
  };
  status: string;
  syncError: string;
  syncStatus: string;
}

export interface Product {
  _id: string;
  listingId: string;
  merchantId: string;
  integrationId: string;
  integrationConnectionId: string;
  integrationConnectionName: string;
  expectedQuantity: number;
  name: string;
  description: string;
  integrationName: string;
  images: string[];
  upc: string[];
  ean: string[];
  searchTerms: string[];
  status: string;
  isBundle: boolean;
  merchant: {
    name: string;
  };
  marketplaceData: MarketplaceData;
  isDeleted: boolean;
  deletedAt: any;
  bundleItems: any[];
  marketplaceListings: any[];
  createdAt: string;
  updatedAt: string;
  inventory?: {
    availableQuantity: number;
    quantitiesByExpiry?: Record<string, number>;
  };
  prepDetails?: any;
  labelOwner?: string;
  prepOwner?: string;
  __v: number;
}

export interface MarketplaceData {
  fnSku: string;
  asin: string;
  sellerSku: string;
  fulfillmentType?: string;
  marketplaceId?: string;
  barcode?: string;
  perishable?: boolean;
}

interface BundleItem {
  quantity: number;
}

interface Listing {
  isListedOnMarketplace: boolean;
  listingId: string;
  merchantId: string;
  integrationId: string;
  integrationName: string;
  integrationConnectionId: string;
  integrationConnectionName: string;
  name: string;
  description: string;
  images: string[];
  upc: string[];
  ean: string[];
  searchTerms: string[];
  status: string;
  isBundle: boolean;
  marketplaceData: MarketplaceData;
  isDeleted: boolean;
  deletedAt: any;
  bundleItems: BundleItem[];
  marketplaceListings: any[];
  createdAt: string;
  updatedAt: string;
}

interface MobileNumber {
  countryCode: string;
  number: string;
}

interface BillingAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  country: {
    code: string;
    name: string;
  };
}

export interface Merchant {
  name: string;
  merchantId: string;
  organizationId: string;
  mobileNumber: MobileNumber;
  email: string;
  status: string;
  billingAddress: BillingAddress;
  isDeleted: boolean;
  deletedAt: any;
  createdAt: string;
  updatedAt: string;
}

export interface InventoryData {
  inventoryId: string;
  sku: string;
  warehouseId: string;
  locationId: string;
  merchantId: string;
  totalQuantity: number;
  allocatedQuantity: number;
  isDeleted: boolean;
  deletedAt: any;
  createdAt: string;
  updatedAt: string;
  listing: Listing;
  unfulfillableQuantity: number;
  merchant: Merchant;
  expectedQuantity: number;
}

export interface Upload {
  fileUploadId: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  uploadType: string;
  uploadTimestamp: string;
  processingStatus: string;
  retryCount: number;
  metadata: {
    warehouseId: string;
    reason: string;
  };
  processingResult: {
    data: {
      status: string;
      errors?: {
        sku: string;
        merchantId: string | null;
        error: string;
        row: number;
      }[];
    };
  };
}

export enum FbaPackingTabsEnums {
  PACKING_OPTIONS = 'Packing Options',
  BOX_INFORMATION = 'Box Information',
  PLACEMENT_OPTIONS = 'Placement Options',
  DELIVERY_WINDOW = 'Delivery Window',
  LABEL_GENERATION = 'Label Generation',
}

export enum FbaPackingLtlFlowTabsEnums {
  PLACEMENT_OPTIONS = 'Placement Options',
  BOX_INFORMATION = 'Box Information',
  TRANSPORTATION_OPTIONS = 'Transportation Options',
  DELIVERY_WINDOW = 'Delivery Window',
  LABEL_GENERATION = 'Label Generation',
}

export enum PrepCategoryEnum {
  ADULT = 'ADULT',
  BABY = 'BABY',
  FC_PROVIDED = 'FC_PROVIDED',
  FRAGILE = 'FRAGILE',
  GRANULAR = 'GRANULAR',
  HANGER = 'HANGER',
  LIQUID = 'LIQUID',
  PERFORATED = 'PERFORATED',
  SET = 'SET',
  SHARP = 'SHARP',
  SMALL = 'SMALL',
  TEXTILE = 'TEXTILE',
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
}

export enum PrepTypeEnum {
  ITEM_BLACK_SHRINKWRAP = 'ITEM_BLACK_SHRINKWRAP',
  ITEM_BLANKSTK = 'ITEM_BLANKSTK',
  ITEM_BOXING = 'ITEM_BOXING',
  ITEM_BUBBLEWRAP = 'ITEM_BUBBLEWRAP',
  ITEM_CAP_SEALING = 'ITEM_CAP_SEALING',
  ITEM_DEBUNDLE = 'ITEM_DEBUNDLE',
  ITEM_HANG_GARMENT = 'ITEM_HANG_GARMENT',
  ITEM_LABELING = 'ITEM_LABELING',
  ITEM_NO_PREP = 'ITEM_NO_PREP',
  ITEM_POLYBAGGING = 'ITEM_POLYBAGGING',
  ITEM_RMOVHANG = 'ITEM_RMOVHANG',
  ITEM_SETCREAT = 'ITEM_SETCREAT',
  ITEM_SETSTK = 'ITEM_SETSTK',
  ITEM_SIOC = 'ITEM_SIOC',
  ITEM_SUFFOSTK = 'ITEM_SUFFOSTK',
  ITEM_TAPING = 'ITEM_TAPING',
}
