import { SignUp } from '@clerk/clerk-react';

import React from 'react';

// const CheckmarkSVG = () => (
//   <svg
//     width='20'
//     height='20'
//     className='mr-2'
//     viewBox='0 0 27 27'
//     fill='none'
//     xmlns='http://www.w3.org/2000/svg'
//   >
//     <path
//       fillRule='evenodd'
//       clipRule='evenodd'
//       d='M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27ZM19.7557 11.3182C20.4148 10.6592 20.4148 9.59077 19.7557 8.93176C19.0967 8.27275 18.0283 8.27275 17.3693 8.93176L11.8125 14.4885L9.63074 12.3068C8.97173 11.6477 7.90327 11.6477 7.24426 12.3068C6.58525 12.9658 6.58525 14.0342 7.24426 14.6932L10.6193 18.0682C11.2783 18.7273 12.3467 18.7273 13.0057 18.0682L19.7557 11.3182Z'
//       fill='#224E73'
//     />
//   </svg>
// );
export const hubSpotMeetingLink =
  'https://meetings-eu1.hubspot.com/anurag-singh/hopstack-ignite-meeting-scheduler';

type Props = {
  initialValues?: {
    emailAddress: string;
  };
};

const SignUpPage = (props: Props) => {
  return (
    <>
      <div className='flex flex-row items-center justify-evenly h-full  bg-gray-100'>
        <div className='flex flex-col  justify-evenly w-full'>
          <div className='flex flex-row justify-around w-full mb-6 '>
            <div className='flex flex-col items-center justify-start'>
              <div className='flex items-start w-full mb-10 '>
                <img
                  src='https://hopstack-pub.s3.amazonaws.com/hopstack-ignite-logo-blue.png'
                  alt='Ignite Fulfill HS Ignite'
                  className='h-7 mt-10  '
                />
              </div>

              <div className='flex flex-col items-start w-full mb-5'>
                <h3 className='text-md text-orange-400 font-bold '>
                  Try Hopstack’s Ignite for Free
                </h3>
                <h1 className='text-3xl font-semibold '>
                  Create your <span className='text-blue-400'>free</span> account or log in.
                </h1>
                <p className=' font-normal text-[14px] text-black mb-12 max-w-sm'>
                  Efficiently handle numerous client or merchant accounts all from a singular
                  platform. Invest more time scaling your prep center business not on spreadsheets,
                  email or time consuming operations.
                </p>

                {/* <div>
                  <h2 className='text-2xl font-bold mb-4'>
                    Start with a 14 days trial and access all features.
                  </h2>
                  <ul className='list-disc pl-6'>
                    <li className='flex items-center mb-2 '>
                      <CheckmarkSVG />A unified interface to view and manage all merchant accounts.
                    </li>
                    <li className='flex items-center mb-2'>
                      <CheckmarkSVG />
                      Integrate seamlessly Amazon Seller Central accounts.
                    </li>
                    <li className='flex items-center mb-2'>
                      <CheckmarkSVG />
                      Allow client access for them to keep track of fulfilment operations.
                    </li>
                    <li className='flex items-center'>
                      <CheckmarkSVG />
                      Download shipping labels from Amazon
                    </li>
                  </ul>
                </div> */}
              </div>

              <div className='flex flex-col item-start justify-start w-full mt-10'>
                <span className='text-black text-md font-bold '>
                  Want to jump on a call with us?
                </span>

                <span className='text-gray-600 text-sm '>
                  We are here to help you optimize your prep center business -<br />
                  <a href={hubSpotMeetingLink} className='text-blue-600 hover:underline'>
                    Schedule a meeting here
                  </a>
                </span>
              </div>
            </div>

            <div className='hover:scale-105 transition-transform duration-500 '>
              <div className='h-full w-full '>
                <div className='flex justify-center pt-10'>
                  <SignUp
                    afterSignInUrl={'/'}
                    afterSignUpUrl={'/?new_user=true'}
                    signInUrl={'/'}
                    appearance={{
                      elements: {
                        formButtonPrimary: 'bg-[#224E73]  hover:bg-[#3C688D] text-sm normal-case',
                        footerActionLink: 'text-[#224E73]  hover:text-[#3C688D] normal-case',
                      },
                    }}
                    initialValues={{
                      emailAddress: props.initialValues?.emailAddress
                        ? props.initialValues.emailAddress
                        : '',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className='flex flex-row text-[11px] text-[#224E73] justify-evenly w-full mb-4 mt-[-30px] '>
        <div className=' w-full ml-20 '>Copyright Hopstack Ignite by Hopstack©, 2024</div>
        <div className='flex flex-row  justify-center w-full '>
          <a
            href='https://www.hopstack.io/privacy-policy'
            className=' hover:underline mr-2'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
          <a
            href='https://www.hopstack.io/terms-of-service'
            className=' hover:underline mx-2'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Service
          </a>
          <a
            href='https://www.hopstack.io/terms-of-use'
            className=' hover:underline ml-2'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Use
          </a>
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
