import React from 'react';
import { SignIn } from '@clerk/clerk-react';
import { hubSpotMeetingLink } from './SignUpPage';

const LoginPage = () => {
  return (
    <>
      <div className='flex flex-row items-center justify-evenly h-full px-8 w-full bg-gray-100'>
        <div className='flex flex-col  justify-evenly w-full'>
          <div className='flex flex-row justify-around w-full mb-6 '>
            <div className='flex flex-col items-center justify-start'>
              <div className='flex items-start w-full mb-20 gap-3'>
                <img
                  src='https://hopstack-pub.s3.amazonaws.com/hopstack-ignite-logo-blue.png'
                  alt='Ignite Fulfill HS Ignite'
                  className='h-7 mt-10  '
                />
              </div>

              <div className='flex flex-col items-start w-full mb-6'>
                <h1 className='text-3xl font-bold '>Welcome back to</h1>
                <h1 className='text-5xl font-bold text-[#224E73] mb-12'>Hopstack Ignite</h1>
                <p className='text-gray-600 text-center'>
                  <b> Log in to your account and start fulfilling orders.</b>
                </p>
                <p className='text-gray-600 text-center text-sm mb-6'>
                  {` Don't have an account yet? Sign up using the form.`}
                </p>
              </div>

              <div className='flex flex-col gap-3 mt-10'>
                <span className='text-black text-md font-bold '>
                  Want to jump on a call with us?
                </span>

                <span className='text-gray-600 text-sm '>
                  We are here to help you optimize your prep center business -<br />
                  <a href={hubSpotMeetingLink} className='text-blue-600 hover:underline'>
                    Schedule a meeting here
                  </a>
                </span>
              </div>
            </div>

            <div className='hover:scale-105 transition-transform duration-500 '>
              <div className='h-full w-full '>
                <div className='flex justify-center pt-10'>
                  <SignIn
                    afterSignInUrl={'/'}
                    afterSignUpUrl={'/?new_user=true'}
                    signUpUrl={'/signup'}
                    appearance={{
                      elements: {
                        formButtonPrimary: 'bg-[#224E73]  hover:bg-[#3C688D] text-sm normal-case',
                        footerActionLink: 'text-[#224E73]  hover:text-[#3C688D] normal-case',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className='flex flex-row text-[11px] text-[#224E73] justify-evenly w-full mb-4 mt-[-30px] '>
        <div className=' w-full ml-20 '>Copyright Hopstack Ignite by Hopstack©, 2024</div>
        <div className='flex flex-row  justify-center w-full '>
          <a
            href='https://www.hopstack.io/privacy-policy'
            className=' hover:underline mr-2'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
          <a
            href='https://www.hopstack.io/terms-of-service'
            className=' hover:underline mx-2'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Service
          </a>
          <a
            href='https://www.hopstack.io/terms-of-use'
            className=' hover:underline ml-2'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Use
          </a>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
