import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import Button from '../components/common/Button';
import pLimit from 'p-limit';

interface FbaShipmentDetailsProps {
  data: any[];
  setShowNotification: (notification: { show: boolean; type: string; content: string }) => void;
  onChangeData: (shipmentId: string, boxId: string, field: string, value: string) => void;
}

const FbaShipmentDetails: React.FC<FbaShipmentDetailsProps> = ({
  data,
  setShowNotification,
  onChangeData,
}) => {
  const [expandedShipmentId, setExpandedShipmentId] = useState(null);

  const hasValidLabels = (shipment: any): boolean => {
    return (
      shipment?.shipmentDetails?.boxLabels?.[0]?.publicUrl &&
      shipment?.shipmentDetails?.shippingLabels
    );
  };

  const downloadFiles = async (
    labels: { bucket: string; filePath: string; publicUrl: string }[],
    labelType: string,
  ): Promise<void> => {
    const limit = pLimit(5);
    try {
      const downloadPromises = labels.map((label, index) => {
        return limit(async () => {
          try {
            const link = document.createElement('a');
            link.href = label.publicUrl;
            const filename = `${labelType}_${index + 1}.pdf`;
            link.download = filename;
            link.click();

            setShowNotification({
              show: true,
              type: 'success',
              content: `Successfully downloaded: ${filename}`,
            });
          } catch (error) {
            setShowNotification({
              show: true,
              type: 'error',
              content: `Error downloading file: ${error.message}`,
            });
            throw error;
          }
        });
      });

      await Promise.all(downloadPromises);
    } catch (error) {
      console.error('Error in downloadFiles:', error);
    }
  };

  return (
    <div className='p-6'>
      {data.map((shipment) => (
        <div key={shipment.marketplaceData.shipmentId} className='border-b pb-4 last:border-b-0'>
          <div
            className='flex cursor-pointer items-center justify-between'
            onClick={() =>
              setExpandedShipmentId((prevId) =>
                prevId === shipment.marketplaceData.shipmentId
                  ? null
                  : shipment.marketplaceData.shipmentId,
              )
            }
          >
            <div className='flex items-center gap-4'>
              <span className='text-gray-500'>Shipment ID:</span>
              <span>{shipment.marketplaceData.shipmentId}</span>
            </div>
            <div className='flex items-center gap-1 text-gray-500'>
              <span>Status: {shipment.status}</span>
              {expandedShipmentId === shipment.marketplaceData.shipmentId ? (
                <ChevronUpIcon className='h-5 w-5' />
              ) : (
                <ChevronDownIcon className='h-5 w-5' />
              )}
            </div>
          </div>
          {expandedShipmentId === shipment.marketplaceData.shipmentId && (
            <div className='mt-4'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-4'>
                  <span className='text-gray-500'>Carrier:</span>
                  {shipment.isCarrierEditDisabled ? (
                    <span>{shipment.carrier}</span> // Show carrier as text if disabled
                  ) : (
                    <input
                      type='text'
                      className='w-full rounded border border-gray-300 p-2'
                      placeholder='Enter carrier name'
                      value={shipment.carrier}
                      onChange={(e) =>
                        onChangeData(
                          shipment.marketplaceData.shipmentId,
                          null,
                          'carrier',
                          e.target.value,
                        )
                      }
                    />
                  )}
                </div>
                <div className='flex gap-4'>
                  <Button
                    className='text-base font-medium'
                    disabled={
                      !hasValidLabels(shipment) || !shipment.shipmentDetails?.boxLabels?.length
                    }
                    onClick={() => {
                      downloadFiles(shipment.shipmentDetails?.boxLabels, 'BoxLabel');
                    }}
                  >
                    Download Box Labels
                  </Button>
                  <Button
                    variant='primary'
                    className='text-base font-medium'
                    disabled={
                      !hasValidLabels(shipment) || !shipment.shipmentDetails?.shippingLabels
                    }
                    onClick={() => {
                      downloadFiles([shipment.shipmentDetails?.shippingLabels], 'ShippingLabel');
                    }}
                  >
                    Download Shipping Label
                  </Button>
                </div>
              </div>
              <div className='mt-4'>
                {shipment.shipmentPallets?.length > 0 ? (
                  <div className='flex items-center gap-4'>
                    <span className='text-gray-500'>Freight Bill Number:</span>
                    {shipment.isFreightBillEditDisabled ? (
                      <span>{shipment.freightBillNumbers?.join(', ') || ''}</span>
                    ) : (
                      <input
                        type='text'
                        className='w-full rounded border border-gray-300 p-2'
                        placeholder='Enter Freight bill numbers (comma-separated)'
                        value={shipment.freightBillNumbers?.join(', ') || ''}
                        onChange={(e) =>
                          onChangeData(
                            shipment.marketplaceData.shipmentId,
                            null,
                            'freightBillNumbers',
                            e.target.value,
                          )
                        }
                      />
                    )}
                  </div>
                ) : (
                  shipment.boxes.map((box) => (
                    <div key={box.boxId} className='border-b pb-2 last:border-b-0'>
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-4'>
                          <span className='text-gray-500'>Box ID:</span>
                          <span>{box.boxId}</span>
                          <span>
                            (
                            {box?.templateName?.replace(/P|B/g, (match) =>
                              match === 'P' ? 'Group-' : 'Box-',
                            )}
                            )
                          </span>
                        </div>
                        <div className='flex items-center gap-4'>
                          <span className='text-gray-500'>Tracking Number:</span>
                          {shipment.trackingNumbers.find((t) => t.boxId === box.boxId)
                            ?.isTrackingEditDisabled ? (
                            <span>
                              {shipment.trackingNumbers.find((t) => t.boxId === box.boxId)
                                ?.trackingId || ''}
                            </span> // Show tracking number as text if disabled
                          ) : (
                            <input
                              type='text'
                              className='w-full rounded border border-gray-300 p-2'
                              placeholder='Enter tracking number'
                              value={
                                shipment.trackingNumbers.find((t) => t.boxId === box.boxId)
                                  ?.trackingId || ''
                              }
                              onChange={(e) =>
                                onChangeData(
                                  shipment.marketplaceData.shipmentId,
                                  box.boxId,
                                  'trackingId',
                                  e.target.value,
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FbaShipmentDetails;
