import React from 'react';
import { Popconfirm } from 'antd';
import type { ButtonProps } from 'antd/es/button';

interface CustomPopconfirmProps {
  title: string;
  onConfirm: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  icon?: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactNode;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
}

const CustomPopconfirm: React.FC<CustomPopconfirmProps> = ({
  title,
  onConfirm,
  onCancel,
  okText = 'Yes',
  cancelText = 'No',
  icon,
  placement = 'top',
  children,
  okButtonProps,
  cancelButtonProps,
  ...rest
}) => {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      placement={placement}
      icon={icon}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      {...rest}
    >
      {children}
    </Popconfirm>
  );
};

export default CustomPopconfirm;
