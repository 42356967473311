import React from 'react';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';
import ReactDOMServer from 'react-dom/server';
import TwoDBarcodeLabel from '../components/common/TwoDBarcodeLabel';

export const Download2DBarcodeLabel = async (
  barcodeString: string,
  barcodeStringSplitByLine: string,
  group: any,
  order: any,
  boxName: string,
  isProvisional: boolean = false,
): Promise<void> => {
  if (!group || !group.items) {
    throw new Error('Invalid group data');
  }

  const box = group.boxes.find((item) => item.boxName === boxName);

  const htmlString = ReactDOMServer.renderToString(
    React.createElement(TwoDBarcodeLabel, {
      barcodeString,
      barcodeStringSplitByLine,
      group,
      order,
      box,
      isProvisional,
    }),
  );

  const tempDiv = document.createElement('div');
  document.body.appendChild(tempDiv);
  tempDiv.innerHTML = htmlString;

  const labelContainers = Array.from(tempDiv.getElementsByTagName('div')).filter(
    (div) => div.id?.startsWith('label-container-'),
  );

  if (labelContainers.length === 0) {
    throw new Error('No label containers found');
  }

  const pdfFilename = `${order.outboundOrderId}_${boxName}.pdf`;

  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'in',
    format: [4, 6],
  });

  for (let index = 0; index < labelContainers.length; index++) {
    const container = labelContainers[index];
    const dataUrl = await htmlToImage.toPng(container, { quality: 1 });

    pdf.addImage(dataUrl, 'PNG', 0, 0, 4, 6);

    if (index !== labelContainers.length - 1) {
      pdf.addPage();
    }
  }

  pdf.save(pdfFilename);
  tempDiv.remove();
};
